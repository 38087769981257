
  import { Component, Vue } from "vue-property-decorator";
  import { IUserProfileUpdate } from "@/interfaces";
  import { readUserProfile } from "@/store/main/getters";
  import { dispatchUpdateUserProfile } from "@/store/main/actions";
  import { required, confirmed, email } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });
  extend("confirmed", { ...confirmed, message: "Passwords do not match" });
  extend("email", { ...email, message: "Invalid email address" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class UserProfileEdit extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    public valid = true;
    public password1 = "";
    public password2 = "";

    get userProfile() {
      return readUserProfile(this.$store);
    }

    public onReset() {
      this.password1 = "";
      this.password2 = "";
      this.$refs.observer.reset();
    }

    public cancel() {
      this.$router.back();
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();
      if (!success) {
        return;
      }

      const updatedProfile: IUserProfileUpdate = {};
      updatedProfile.password = this.password1;
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
      this.$router.push("/main/profile");
    }
  }
